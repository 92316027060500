export default class StrokeJoin
{
	static get Miter()
	{
		return 0;
	}

	static get Round()
	{
		return 1;
	}

	static get Bevel()
	{
		return 2;
	}
}