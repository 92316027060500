export default class FillRule
{
	static get EvenOdd()
	{
		return 0;
	}

	static get NonZero()
	{
		return 1;
	}
}