export default class TrimPath
{
	static get Off()
	{
		return 0;
	}

	static get Sequential()
	{
		return 1;
	}

	static get Synced()
	{
		return 2;
	}
}